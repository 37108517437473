/* * {
    color: #9f7866 !important;
    font-family: 'Montserrat', sans-serif;
} */

* {
    user-select: none;
}

.bottomSwitchTabs {
    position: fixed;
    bottom: 0;
    z-index: 20;
    width: 100%;
    text-align: center;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 1);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-bottom: 10px;
    background-color: #FBF7F4;
}

._3iVQ0 {
    top: 40px !important;
    /*gallery height position*/
}

._1eGao {
    /* next image visible so to adjust height and width in gallery */
    height: 102% !important;
    position: relative;
    left: -10px !important;
    width: 100vw !important;
}

.centerControls {
    position: absolute;
    top: 80vh;
    right: 10%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 80%;
}

.bottomControls {
    position: absolute;
    top: 80vh;
    right: 5%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 90%;
}

.likedStories {
    position: absolute;
    display: flex;
    width: 90%;
    max-height: max-content;
    bottom: 2.5%;
    left: 5%;
    right: 5%;
    color: white;
}

.popupWidth {
    max-width: 30vw;
    padding: 0 30px;
}

.rightCenterControls {
    width: 35%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.rightCenterControls div {
    display: inline-block;
    vertical-align: middle;
    margin-left: 20%;
}

.leftCenterControls {
    width: 35%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.topCenterControls {
    display: flex;
    align-items: center;
}

.leftCenterControls div {
    display: inline-block;
    vertical-align: middle;
    margin-right: 20%;
}

.topSwitchTabs {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
    padding: 10px 0;
    padding-top: 70px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    position: fixed;
    z-index: 15;
    width: 100%;
    color: white;
}

.galleryHide {
    margin-left: 20px;
    top: 16px;
    right: 140px;
    font-size: x-small;
    padding: 2px 7px;
    border-radius: 5px;
    font-weight: 600;
    cursor: pointer;
}

._lhmht {
    /* play button position in gallery */
    right: 40px;
    left: auto;
    top: 6px !important;
}

.photoName {
    position: absolute;
    top: 14px;
    color: white;
    font-size: smaller;
    width: max-content;
    height: min-content;
    left: 50px;
    background-color: transparent !important;
}

.tooltip {
    position: relative;
    display: flex;
    cursor: pointer;
}

.tooltip .tooltiptext {
    width: max-content;
    background-color: rgb(30, 30, 30);
    color: #fff;
    text-align: center;
    padding: 5px 12px;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    top: 100%;
    left: 0;
    opacity: 0;
    transition: opacity 1s ease-in-out;
    pointer-events: none;
}

.tooltip .tooltiptext::after {
    content: '';
    position: absolute;
    top: -15px;
    left: 20px;
    transform: translateX(-50%);
    border-width: 8px;
    border-style: solid;
    border-color: transparent transparent rgb(30, 30, 30) transparent;
}

.tooltip .homeTip {
    width: max-content;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 12px;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    top: 10px;
    opacity: 0;
    transition: opacity 1s ease-in-out;
    pointer-events: none;
}

.tooltip .homeTip::after {
    content: '';
    position: absolute;
    top: -15px;
    left: 20px;
    transform: translateX(-50%);
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent rgb(30, 30, 30) transparent;
}

.tooltip .tooltiptext.show {
    opacity: 0.8;
}

.tooltip .tooltiptext.hide {
    display: none;
}

.homeTip {
    top: 17px;
    left: -7px;
    background-color: black;
    font-weight: 900;
}

.workFooter {
    /* position: fixed;
    top: 55px; */
    z-index: 20;
    width: 100%;
    text-align: center;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    /* display: flex; */
    display: none;
    justify-content: space-around;
    padding: 15px 0;
    background-color: rgba(0, 0, 0, 0.7);
    color: #FBF7F4;
    max-height: 55px;
}

.scrollToTop {
    position: fixed;
    bottom: 12vh;
    right: 1rem;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 50%;
    padding: 11px 16px;
    z-index: 20;
    cursor: pointer;
}

.nextCollection {
    border: 1px solid black;
    color: white;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 5px 12px;
    border-radius: 1vh;
    font-size: larger;
}

#hideButton {
    width: 30px;
    height: 38px;
    display: flex;
    margin-right: 15px;
    align-items: center;
    cursor: pointer;
}

#allDownloadButton {
    width: 30px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-right: 15px;
}

.inactiveClass {
    pointer-events: none;
}

.displayNone {
    display: none !important;
}

.filterOptions {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    padding-bottom: 0;
    position: fixed;
    width: 100%;
    z-index: 10;
    background-color: #FBF7F4;
}

.searchCollectionTitle {
    text-align: left;
    padding: 10px 20px;
    font-size: large;
    font-weight: 600;
}

.invert-image {
    filter: invert(100%) brightness(2);
}

#hideDiv,
#addToDiv,
#downloadDiv {
    padding: 5px 12px;
    border-radius: 5px;
    margin-top: 10px;
    color: white;
}

._3q7r8 {
    background-color: rgb(30, 30, 30);
    /*gallery background color*/
}


.imgStory {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    padding: 5px;
    min-height: 100px;
    min-width: 100px;
}

.hdButton {
    margin-left: 20px;
    top: 16px;
    right: 360px;
    color: black;
    background-color: rgb(114, 115, 116);
    font-size: x-small;
    padding: 2px 7px;
    border-radius: 5px;
    font-weight: 900;
    cursor: pointer;
}

.noDownload {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: large;
    position: fixed;
    width: 100vw;
    height: 69vh;
    z-index: 20;
    left: 0;
    background-color: rgba(0, 0, 0, 0.7);
}

.noHidden {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: large;
    position: fixed;
    top: 31%;
    width: 100vw;
    height: 69vh;
    z-index: 20;
    left: 0;
    background-color: rgba(0, 0, 0, 0.7);
}

.likeDiv {
    margin-left: 20px;
    top: 20px;
    right: 100px;
    cursor: pointer;
}

._3WRGR {
    /* default icons width in gallery */
    width: 0.9rem;
}

.activeHD {
    margin-left: 20px;
    top: 16px;
    right: 360px;
    color: black;
    background-color: white;
    font-size: x-small;
    padding: 2px 7px;
    border-radius: 5px;
    font-weight: 600;
    cursor: pointer;
}

.pillImgStory {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
    padding: 3px;
    margin-right: 10px;
    border: 2px solid white;
}

.adminSwitchOverlay {
    position: fixed;
    padding: 10px 20px;
    border-radius: 10px;
    z-index: 50;
    width: max-content;
    bottom: 8%;
    left: 50%;
    font-size: 18px;
    font-weight: 900;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    box-shadow: rgba(0, 0, 0, 0.8) 0px 2px 20px
}

.toggleText {
    border: 1px solid white;
    border-radius: 20px;
    padding: 2px 10px;
    font-size: 15px;
    cursor: pointer;
    color: black;
    background-color: white;
}

.tnCOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 20;
    display: flex;
    align-items: center;
    justify-content: center;

}

.tnCPopup {
    background: white;
    border-radius: 10px;
    width: 90%;
    height: 90%;
    padding: 60px 40px;
    font-size: 18px;
}

.tnCText {
    overflow-y: auto;
    max-height: 90%;
    scrollbar-width: thin;
    scrollbar-color: #888 #f0f0f0;
    text-align: left;
}

.tnCText ul {
    padding: 0 !important;
}

.tnCText li {
    font-size: 15px;
}

.tnCText p {
    padding: 5px;
}

.storyDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    padding: 5px 0;
    margin: 20px 30px;
}

.pillStoryDiv {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 3px;
    padding-right: 8px;
    margin: 20px;
    border: 1px solid black;
    border-radius: 4vh;
    min-width: 220px;
    max-width: 220px;
    overflow: auto;
}

.carouselButtons {
    position: absolute;
    top: 50%;
    border-radius: 0;
    transform: translateY(-50%);
}

#hidePopupDiv {
    background-color: rgba(0, 0, 0, 0.7);
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 300;
    top: 0;
    left: 0;
    display: none;
    justify-content: center;
    align-items: center;
}

#mediaHidePopup {
    background-color: rgba(0, 0, 0, 0.7);
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 300;
    top: 0;
    left: 0;
    display: none;
    justify-content: center;
    align-items: center;
}

#grideHidePopup {
    background-color: rgba(0, 0, 0, 0.7);
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 300;
    top: 0;
    left: 0;
    display: none;
    justify-content: center;
    align-items: center;
}

#hidePhotoPopup {
    background: white;
    padding: 20px 30px;
    border-radius: 10px;
    text-align: left;
    width: fit-content;
    margin: 20px;
}

#hideMediaPopup {
    background: white;
    padding: 20px 30px;
    border-radius: 10px;
    text-align: left;
    width: fit-content;
    margin: 20px;
}

#gridHidePopup {
    background: white;
    padding: 20px 30px;
    border-radius: 10px;
    text-align: left;
    width: fit-content;
    margin: 20px;
}

#gridHidePopup h2 {
    font-size: inherit !important;
}

#hidePhotoPopup h1 {
    font-family: 'Montserrat';
    font-size: 22px;
    letter-spacing: 0.5px;
    margin-bottom: 12px;
    margin-top: -4px;
    font-weight: 700;
}

#hideMediaPopup h1 {
    font-family: 'Montserrat';
    font-size: 22px;
    letter-spacing: 0.5px;
    margin-bottom: 12px;
    margin-top: -4px;
    font-weight: 700;
}

#gridPopupDesc h2 {
    font-family: 'Montserrat';
    font-size: 18px;
    margin-bottom: 20px;
    font-weight: bolder;
}


#gridHidePopup h3 {
    font-weight: 700;
    font-family: 'Montserrat';
    margin-bottom: 5px;
}

#gridHidePopup li {
    font-size: small !important;
}

#cancelHideButton {
    font-family: 'Montserrat';
    font-size: 15px;
    width: max-content;
    font-weight: 700;
    padding: 6px;
    background: white;
    text-align: center;
    color: black;
    margin-top: 15px;
    margin-right: 15px;
    border: none;
    border-radius: 0.3rem;
    float: right;
    cursor: pointer;
}

#hideButtonGallery {
    font-family: 'Montserrat';
    font-size: 15px;
    width: max-content;
    padding: 6px 12px;
    text-align: center;
    color: white;
    margin-top: 15px;
    margin-right: 15px;
    border: 1px solid;
    border-radius: 0.3rem;
    float: right;
    cursor: pointer;
}

#hideButtonMedia {
    font-family: 'Montserrat';
    font-size: 15px;
    width: max-content;
    padding: 6px 12px;
    text-align: center;
    color: white;
    margin-top: 15px;
    margin-right: 15px;
    border: 1px solid;
    border-radius: 0.3rem;
    float: right;
    cursor: pointer;
}

#hidePhotoPopup h2 {
    font-family: 'Montserrat';
    font-size: 18px !important;
    margin-bottom: 20px;
}

#hidePhotoPopup h3 {
    font-weight: 700;
    font-family: 'Montserrat';
    margin-bottom: 5px;
}

#hidePhotoPopup li {
    font-size: small;
}

#hideMediaPopup h2 {
    font-family: 'Montserrat';
    font-size: 18px !important;
    margin-bottom: 20px;
}

#hideMediaPopup h3 {
    font-weight: 700;
    font-family: 'Montserrat';
    margin-bottom: 5px;
}

#hideMediaPopup li {
    font-size: small;
}

#grideHideButtonDiv {
    text-align: right;
}

.galleryAddto {
    margin-left: 20px;
    top: 13px;
    right: 240px;
    color: black;
    background-color: white;
    font-size: x-small;
    border: 1px solid white;
    border-radius: 5px;
    padding: 5px 12px;
    cursor: pointer;
}

.albumStoryDiv {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0.2, 0.2, 0.2, 0.2);
    width: 35rem;
    height: 26rem;
    cursor: pointer;
    margin: 20px;
    border-radius: 2%;
    padding-bottom: 2rem;
    position: relative;
}

.videoStoryDiv {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0.2, 0.2, 0.2, 0.2);
    width: 35rem;
    height: 26rem;
    cursor: pointer;
    margin: 20px;
    border-radius: 2%;
    padding-bottom: 2rem;
    position: relative;
}

.coloredImage {
    filter: invert(90%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(90%) contrast(90%);
    cursor: pointer;
}

.coloredImage:hover {
    filter: invert(100%) brightness(2);
}

.cardDownload {
    padding: 3px 6px;
    border: 1px solid black;
    border-radius: 10px;
}

.unhideBtn {
    border-radius: 10px;
    padding: 5px 10px;
    background-color: white;
    display: inline;
    cursor: pointer;
}

.hiddenShareCard {
    font-size: 0.9rem;
    font-weight: 600;
    text-align: center;
    padding: 30px 35px;
    position: absolute;
}

.hiddenShareCardText {
    color: white;
    margin-bottom: 14px;
}

.tabsPara {
    margin: 0 1rem;
    display: flex;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 5px;
    cursor: pointer;
}

.colSpin {
    padding-top: 15%;
}

.shareMediaDiv img {
    width: 20px;
    margin-right: 10px;
}

.shareMediaDiv {
    display: flex;
    align-items: center;
    margin-right: 20px;
    cursor: pointer;
    position: relative;
    border-radius: 10px;
    padding: 5px;
}

.lockDiv img {
    width: 20px;
    margin-right: 10px;
}

.lockDiv {
    display: flex;
    align-items: center;
    margin-right: 20px;
    cursor: pointer;
    position: relative;
    border-radius: 10px;
    padding: 5px;
}

.skeleton-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;
    padding: 16px;
}

.filterBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100%;
    z-index: 20;
}

.facePopup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 5px;
    padding: 50px;
    max-width: 50%;
    height: 400px;
    scrollbar-width: thin;
    scrollbar-color: #888 #f0f0f0;
}

.likeViewPopup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.7);
    padding: 50px;
    max-width: 50%;
    max-height: 50%;
    min-width: 500px;
    scrollbar-width: thin;
    scrollbar-color: #888 #f0f0f0;
}

#guestName {
    position: absolute;
    bottom: 10px;
    left: 15px;
    color: white;
    border-radius: 5px;
    padding: 3px 8px;
    background-color: rgba(0, 0, 0, 0.7);
    font-size: small;
}

#notDeleteOverlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    top: 0;
    z-index: 10;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.facePopup::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

.facePopup::-webkit-scrollbar-thumb {
    background-color: #FBF7F4;
    border-radius: 10px;
}

.facePopup::-webkit-scrollbar-track {
    background-color: #FBF7F4;
}

.likeViewContent {
    scrollbar-width: thin;
    scrollbar-color: #888 #f0f0f0;
}

.likeViewContent::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

.likeViewContent::-webkit-scrollbar-thumb {
    background-color: #FBF7F4;
    border-radius: 10px !important;
}

.likeViewContent::-webkit-scrollbar-track {
    background-color: #FBF7F4;
}


.pastFacesPara {
    cursor: pointer;
    margin: 10px;
    width: 100px;
    height: 100px;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    min-width: 100px;
}

.singleFacesPara {
    cursor: pointer;
    margin: 10px;
    width: 100px;
    height: 100px;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    min-width: 100px;
}

#pastFacesDiv {
    display: flex;
    overflow: auto;
    width: 100%;
    scrollbar-width: thin;
    scrollbar-color: white white;
}

.faceFilterDiv {
    display: flex;
    align-items: center;
    margin-right: 15px;
    max-height: fit-content;
    border: 1px solid black;
    border-radius: 1vh;
    padding: 5px;
}

.skeleton-item {
    background-color: #f0f0f0;
    border-radius: 8px;
    overflow: hidden;
}

.__JnHV {
    /* photos count size in gallery */
    font-size: smaller;
    background-color: transparent !important;
}

.albumGrid {
    display: grid;
    justify-items: center;
    grid-template-columns: repeat(2, 1fr);
    padding-top: 20px;
    padding-bottom: 20vh;
}

._1oKnM {
    /* fullscreen icon position in gallery */
    top: 6px !important;
}

.downloadButton {
    margin-left: 20px;
    top: 16px;
    right: 195px;
    cursor: pointer;
}

.shareFirstPage {
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    z-index: 12;
    justify-content: center;
    align-items: center;
}

.tooltip .homeTip.show {
    opacity: 1;
}

.shareFaceDiv {
    border: 1px solid 1px solid rgb(236 227 227);
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2%;
    border-radius: 10px;
    background-color: rgb(236 227 227);
}

.shareOverlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    z-index: 30;
}

.shareSelectiveDiv {
    background: white;
    padding: 24px 0;
    border-radius: 10px;
    max-width: 60vw;
    max-height: 95vh;
}

.shareOverlay .drawer {
    padding: 10px 0 30px 0;
}

.shareGrid {
    display: grid;
    width: 100%;
    padding: 10px;
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    justify-items: center;
    max-width: calc(6 * 180px);
    margin: 0 auto;
    gap: 5px;
    max-height: 50vh;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: thin;
    scrollbar-color: #888 #f0f0f0;
}

.shareSelectiveDone {
    color: white;
    padding: 5px 12px;
    border-radius: 10px;
    font-size: 20px;
}

.downMethod {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 22px;
    border-radius: 10px;
    cursor: pointer;
    color: white;
    min-width: 190px;
    max-width: 190px;
    margin: 15px;
}

.qualityRadio {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    cursor: pointer;
    cursor: pointer;
}

#shareNote li {
    font-size: small !important;
}

.shareCards {
    min-height: 132px;
    min-width: 178px;
    width: 178px;
    height: 132px;
    position: relative;
    cursor: pointer;
}

.allPhotosShare {
    border: 1px solid rgba(0, 0, 0, 0.7);
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 10px 24px;
    border-radius: 10px;
    cursor: pointer;
}

.shareDropdown, .moreDropdown {
    background: #FBF7F4;
    position: absolute;
    right: -10px;
    top: 45px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.7);
    border-radius: 10px;
    z-index: 10;
    color: black;
    width: max-content;
}

.shareDropdown::before , .moreDropdown::before {
    content: "";
    position: absolute;
    top: -20px;
    /* Adjust to point to the share icon */
    right: 5px;
    transform: translateX(-50%);
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent white transparent;
    /* White matches dropdown background */
}

.shareOption {
    cursor: pointer;
    padding: 10px 20px;
    border-bottom: 1px solid #696868;
    transition: background-color 0.2s ease;
}

.moreOption {
    cursor: pointer;
    padding: 10px 20px;
    border-bottom: 1px solid #696868;
    transition: background-color 0.2s ease;
    display: flex;
    align-items: center;
}

.moreImgTag {
    margin-right: 10px;
}

.moreNav {
    border-bottom: 1px solid grey;
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: space-between;
}

.shareOption:last-child {
    border-bottom: none;
}


.videoGrid {
    display: grid;
    justify-items: center;
    padding-top: 20px;
    grid-template-columns: repeat(2, 1fr);
    padding-bottom: 20vh;
}

.dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    top: -1px;
}


.noPhotoDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50vh;
    font-size: large;
    font-weight: 600;
}

.drawer {
    position: fixed;
    bottom: -100%;
    left: 0;
    width: 100%;
    background-color: #fff;
    padding: 30px 0;
    box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    transition: bottom 0.6s ease;
}

.drawer.open {
    bottom: 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#storyNav {
    scrollbar-width: thin;
    scrollbar-color: #FBF7F4 #FBF7F4;
}

#storyNav::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

#storyNav::-webkit-scrollbar-thumb {
    background-color: #FBF7F4;
    border-radius: 10px;
}

#storyNav::-webkit-scrollbar-track {
    background-color: #FBF7F4;
}

.topControls {
    position: absolute;
    top: 18px;
    right: 96px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.downloadDetails {
    text-align: center;
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.downloadData {
    padding-top: 0.2rem;
    width: 30vw;
}

.drawer-content {
    padding: 20px;
    text-align: center;
}

.nextCollectionDiv {
    padding-top: 5%;
    padding-bottom: 5%;
}

.drawer-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
}


input[type="checkbox"] {
    width: 20px;
    height: 20px;
    transform: scale(1);
    margin-right: 10px;
    margin-top: 10px;
}

@media screen and (max-width:1200px) {
    .albumStoryDiv {
        width: 30rem;
        height: 22rem;
    }

    .downMethod {
        min-width: 35vw;
    }

    .facePopup {
        max-width: 90%;
    }

    .moreDropdown {
        bottom: 45px;
        top: auto;
    }

    .adminSwitchOverlay{
        bottom: 12%;
    }
    
    .moreDropdown::before {
        content: "";
        position: absolute;
        top: 150px;
        right: 5px;
        transform: translateX(-50%);
        border-width: 10px;
        border-style: solid;
        border-color: white transparent transparent transparent;
    }

    .shareMediaDiv {
        border: 1px solid white;
    }

    .lockDiv {
        border: 1px solid white;
    }

    .popupWidth {
        max-width: 40vw;
    }

    .shareDropdown::before {
        border-color: transparent transparent white transparent;
        /* White matches dropdown background */
    }

    .tooltip .homeTip::after {
        content: '';
        position: absolute;
        top: 34px;
        left: 35px;
        transform: translateX(-50%);
        border-width: 10px;
        border-style: solid;
        border-color: rgb(30, 30, 30) transparent transparent transparent;
    }

    .videoStoryDiv {
        width: 30rem;
        height: 22rem;
    }

    .nextCollectionDiv {
        padding-bottom: 10%;
    }

    #hideDiv,
    #addToDiv,
    #downloadDiv {
        background-color: white !important;
        color: black;
    }

    .tooltip .homeTip {
        top: -60px;
        left: -100px;
    }
}

@media screen and (max-width:900px) {
    .tabsPara {
        font-size: 15px;
    }

    .downMethod {
        min-width: 40vw;
    }

    .tooltip .tooltiptext {
        top: 120%;
    }

    .facePopup {
        max-width: 90%;
    }


    .albumGrid {
        grid-template-columns: repeat(1, 1fr);
    }

    .noDownload {
        top: 37%;
    }

    .popupWidth {
        max-width: 50vw;
    }

    .noHidden {
        top: 37%;
    }

    .videoGrid {
        grid-template-columns: repeat(1, 1fr);
    }

    .downloadData {
        width: 50vw;
    }

    .tooltip .homeTip {
        width: max-content;
        background-color: rgb(30, 30, 30);
        color: #fff;
        text-align: center;
        padding: 5px 12px;
        border-radius: 6px;
        position: absolute;
        z-index: 1;
        top: -60px;
        opacity: 0;
        transition: opacity 1s ease-in-out;
        pointer-events: none;
    }
    
    .tooltip .homeTip::after {
        content: '';
        position: absolute;
        top: 32px;
        left: 35px;
        transform: translateX(-50%);
        border-width: 10px;
        border-style: solid;
        border-color: rgb(30, 30, 30) transparent transparent transparent;
    }
}

@media (min-width: 600px) and (max-width: 900px) {
    .galleryAddto {
        margin-left: 15px;
        font-size: xx-small;
        right: 220px;
    }

    .topControls {
        right: 85px;
    }

    .popupWidth {
        max-width: 70vw;
        padding: 0 10px;
    }

    .shareGrid {
        max-width: calc(4 * 180px);
    }

    .shareSelectiveDiv {
        max-width: 80vw;
    }

    .hdButton {
        margin-left: 15px;
        font-size: xx-small;
        right: 320px;
    }

    .activeHD {
        margin-left: 15px;
        font-size: xx-small;
        right: 320px;
    }

    .likeDiv {
        margin-left: 15px;
        right: 90px;
    }

    .galleryHide {
        margin-left: 15px;
        right: 130px;
        padding: 0;
    }

    ._lhmht {
        /* play button right position in gallery */
        right: 35px;
    }

    .downloadButton {
        margin-left: 15px;
        right: 180px;
    }

    .downloadData {
        width: 70vw;
    }

}

@media screen and (max-width: 600px) {
    .tabsPara {
        margin: 0 0.5rem;
        font-size: smaller;
        display: flex;
        flex-direction: column;
        padding-bottom: 0;
    }

    .hiddenShareCard {
        padding: 13px 5px;
        font-size: 0.8rem;
    }

    .adminSwitchOverlay {
        font-size: 13px;
        padding: 7px 14px;
        bottom: 13%;
    }

    .moreDropdown {
        top: auto;
        bottom: 64px !important;
        background-color: #FBF7F4;
        padding: 0;
    }

    .moreDropdown::before {
        display: none;
    }

    .moreOption {
        width: 100%;
        border-bottom: none;
    }

    .profileDiv {
        width: 22px !important;
        height: 22px !important;
        font-size: 10px !important;
        padding: 12px !important;
    }

    .tnCPopup {
        padding: 20px;
        font-size: 15px;
    }

    .toggleText {
        font-size: 12px;
    }

    .tnCText li {
        padding: 0;
    }

    #guestName {
        font-size: 12px;
    }

    #notDeleteOverlay {
        font-size: small;
    }

    .carouselButtons {
        top: 42%;
    }

    .downMethod {
        min-width: 50vw;
    }

    .cardDownload {
        font-size: 15px;
    }

    .tooltip .tooltiptext::after {
        content: '';
        position: absolute;
        top: -25px;
        left: 20px;
        border-width: 15px;
        border-style: solid;
        border-color: transparent transparent rgb(30, 30, 30) transparent;
    }

    .shareMediaDiv img {
        width: 15px !important;
        margin-right: 5px;
    }

    .lockDiv img {
        width: 15px !important;
        margin-right: 5px;
    }

    .tooltip .homeTip::after {
        content: '';
        position: absolute;
        top: -50px;
        left: 0px;
        transform: translateX(-50%);
        border-width: 10px;
        border-style: solid;
        border-color: rgb(30, 30, 30) transparent transparent transparent;
    }

    .searchCollectionTitle {
        font-size: 15px;
    }

    .faceFilterDiv {
        margin-right: 10px;
    }

    #hideButton {
        margin-right: 10px;
    }

    #allDownloadButton {
        margin-right: 10px;
    }

    .allPhotosShare {
        font-size: 17px;
        padding: 10px;
    }

    .popupWidth {
        max-width: 80vw;
        padding: 0 10px;
    }

    .shareCards {
        min-width: 134px;
        min-height: 100px;
        width: 134px;
        height: 100px;
    }

    .shareGrid {
        max-width: calc(2 * 180px);
        grid-template-columns: repeat(2, 1fr);
    }

    .leftCenterControls div {
        margin-right: 15%;
    }

    .rightCenterControls div {
        margin-left: 15%;
    }

    #pastFacesDiv {
        width: 90vw;
    }

    .shareSelectiveDiv {
        min-width: 100vw;
        font-size: smaller !important;
        min-height: max-content;
    }

    .tabsPara span {
        font-size: 10px;
    }

    input[type="checkbox"] {
        width: 15px;
        height: 15px;
    }

    .tabsPara img {
        width: 22px !important;
        height: 22px !important;
    }

    ._3iVQ0 {
        /* gallery height position in mobile */
        top: 0 !important;
    }

    .downloadData {
        width: 90vw;
    }


    .storyDiv {
        margin: 20px;
        font-size: 15px;
    }

    .shareMediaDiv {
        margin-right: 10px;
        padding: 5px;
    }

    .lockDiv {
        margin-right: 10px;
        padding: 5px;
    }

    .albumStoryDiv {
        width: 17rem;
        height: 17rem;
        padding-bottom: 1rem;
    }

    .videoStoryDiv {
        width: 17rem;
        height: 17rem;
        padding-bottom: 1rem;
    }


    .albumStoryDiv img {
        padding-bottom: 0.5rem;
    }

    .videoStoryDiv img {
        padding-bottom: 0.5rem;
    }

    .imgStory {
        width: 75px;
        height: 75px;
        padding: 3px;
        min-height: 75px;
        min-width: 75px;
    }

    .downloadButton {
        margin-left: 0;
        top: 93vh;
        right: 25%;
    }

    ._dZ8C- {
        /* full screen icon hide in mobile */
        display: none;
    }

    .hdButton {
        margin-left: 0;
        top: 93.5vh;
        right: 92%;
    }

    .activeHD {
        margin-left: 0;
        top: 93.5vh;
        right: 92%;
    }

    .galleryHide {
        margin-left: 0;
        top: 93.5vh;
        right: 75%;
    }

    .likeDiv {
        margin-left: 0;
        top: 93.5vh;
        right: 10%;
    }

    .galleryAddto {
        margin-left: 0;
        top: 93vh;
        right: 50%;
    }

    ._t1897 {
        /* image height position adjust in mobile in gallery */
        margin-top: 25px !important;
        height: 80% !important;
    }

    .skeleton-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 16px;
        padding: 16px;
    }

    .colSpin {
        padding-top: 50%;
    }

    .workFooter {
        top: 50px;
    }

    .tooltip .tooltiptext {
        width: max-content;
        left: 0;
    }

    .pastFacesPara {
        width: 80px;
        height: 80px;
        min-width: 80px;
    }

    .nextCollection {
        font-size: medium;
    }

    .filterOptions {
        font-size: 12px;
        padding: 15px 5px;
    }

    .filterOptions img {
        width: 25px;
        max-height: 25px !important;
        font-size: smaller;
    }

    .filterOptions img:first-of-type {
        width: 25px;
        max-height: 30px !important;
        font-size: smaller;
    }

    #hideButton {
        justify-content: center;
    }

    .__JnHV {
        position: absolute !important;
        width: 95vw;
        left: -25px;
    }

    .tooltip .homeTip {
        width: max-content;
        background-color: black;
        color: #fff;
        text-align: center;
        padding: 5px 12px;
        border-radius: 6px;
        position: absolute;
        z-index: 1;
        top: -70px;
        left: -25px;
        opacity: 0;
        transition: opacity 1s ease-in-out;
        pointer-events: none;
        font-size: 12px;
    }
    
    .tooltip .homeTip::after {
        content: '';
        position: absolute;
        top: 25px;
        left: 25px;
        transform: translateX(-50%);
        border-width: 10px;
        border-style: solid;
        border-color: rgb(30, 30, 30) transparent transparent transparent;
    }

}


@media screen and (max-width: 400px) {
    .filterOptions img:first-of-type {
        width: 22px;
        max-height: 30px !important;
        font-size: smaller;
    }
}